



























import { Component, Vue, Prop } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {
    QrcodeVue
  }
})
export default class GenericQRCodeModal extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ required: true, type: String }) value!: string
  copyLinkButtonText = 'Copiar Link'

  closeModal () {
    this.$emit('close')
  }

  copyInstructionLink () {
    const field = document.getElementById('qrcode-link') as HTMLInputElement

    field.select()
    field.setSelectionRange(0, 255)

    document.execCommand('copy')

    this.copyLinkButtonText = 'Copiado!'
    setTimeout(() => { this.copyLinkButtonText = 'Copiar Link' }, 3000)
  }
}
